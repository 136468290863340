import Layout from '@/layout'
const activityCenterRouter = {
    component: Layout,
    path: '/activityCenter',
    redirect: '/activityCenter',
    name: '活动中心',
    meta: {
        icon: ''
    },
    children: [
        {
            path: '/activityCenter',
            name: 'activityCenter',
            meta: { title: "活动中心" },
            component: () => import('../views/activityCenter/list.vue')
        },
        {
            path: "/activityCenter/addActivityCenter",
            name: "addActivityCenter",
            meta: { title: "添加活动" },
            component: () => import('../views/activityCenter/addActivityCenter.vue')
        },
        {
            path: '/activityCenter/mienList',
            name: 'mienList',
            meta: { title: "活动风采" },
            component: () => import('../views/activityCenter/mienList.vue')
        },
        {
            path: '/activityCenter/seeList',
            name: 'seeList',
            meta: { title: "查看名单" },
            component: () => import('../views/activityCenter/seeList.vue')
        },
        
    ]

}
export default activityCenterRouter
