export default {
   // api: "http://192.168.2.24:8700/",

   // api: "http://192.168.2.3:8700/", 
   // api:" http://192.168.2.21:8700/",

   api: "https://community.yuhoukeji.cn",
   // api: "http://192.168.20.43:8700",
   // api:'https://village.hemeixiangcun.xn--fiqs8s',
   //  api: "http://192.168.0.132:8087",
   // 测试地址
   //  api: "https://test-yuhong.yuhoukeji.cn",
   // 生产地址
   // api: "https://yuhou.aiteyixia.cn/"

}
        