import axios from '../request/http';

const Login = {
    // 获取验证码
    getCode() {
        return axios.get("/service-care-center/centerAdminLogin/captcha");
    },
    // 登录
    login(params) {
        return axios.get("/service-care-center/centerAdminLogin/login", { params });
    },
}

export default Login;