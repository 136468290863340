import Layout from '@/layout'
const instrumentsPanelRouter = {
    component: Layout,
    path: '/',
    meta: {
        icon: '',
        // title: "仪表盘"
    },
    children: [
        {
            path: "/",
            name: "instrumentsPanelList",
            meta: { title: "仪表盘", keepAlive: true, },// 需要被缓存},
            component: () => import('../views/instrumentsPanel/list.vue')
        },
        // {
        //     path: "/addInstrumentsPanel",
        //     name: "addInstrumentsPanel",
        //     meta: { title: "新增文章", tabBar: true, auth: false },

        //     component: () => import('../views/instrumentsPanel/add.vue')
        // },
    ]
}
export default instrumentsPanelRouter
