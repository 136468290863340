import Layout from '@/layout'
const informationManagementRouter = {
    component: Layout,
    path: '/informationManagement',
    redirect: '/informationList',
    meta: {
        icon: ''
    },
    children: [
        {
            path: '/informationList',
            name: 'informationList',
            meta: { title: "资讯管理" },
            component: () => import('../views/information/list.vue')
        },

    ]
}
export default informationManagementRouter
