import Layout from '@/layout'
const setUserRouter = {
  component: Layout,
  path: '/management',
  name: '康养中心信息设置',
  redirect: '/management/basic',
  meta: {
    title: '康养中心信息设置',
    icon: ''
  },
  children: [
    {
      path: "/management/basic",
      name: "basic",
      meta: { title: "基本资料设置" },
      component: () => import("../views/management/basic/list.vue")
    },
    {
      path: "/management/serve",
      name: "serve",
      meta: { title: "门店服务介绍设置" },
      component: () => import("../views/management/serve/list.vue")
    },
    {
      path: "/management/serve/addServe",
      name: "addServe",
      meta: { title: "新建服务介绍" },
      component: () => import("../views/management/serve/addServe.vue")
    },
    
  ]

}
export default setUserRouter
