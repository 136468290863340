<template>
  <div>
    <div
      style="width: 100%; height: 70px; display: flex; justify-content: center"
    >

      <img
        src="@/assets/logo.png"
        style="width: 35px; height: 35px; margin-top: 19px"
      />
      <h1 class="title" v-if="!fold">康养中心</h1>
    </div>
    <el-menu
      :unique-opened="true"
      router
      :collapse="fold"
      :default-active="myPath"
      class="el-menu-vertical-demo"
      background-color="#373a3d"
      text-color="rgb(191, 203, 217)"
      active-text-color="rgb(64, 158, 255)"
      :collapse-transition="false"
    >
      <template v-for="route in constantRoutes">
        <template v-if="route.childMenus">
          <el-submenu :index="route.id.toString()" :key="route.id">
            <template slot="title">
              <i :class="getIcon(route.icon)"></i>
              <span>{{ route.title }}</span>
            </template>
            <el-menu-item
              :index="child.jump"
              v-for="child in route.childMenus"
              :key="child.jump"
            >
              <i :class="getIcon(child.icon)"></i>
              {{ child.title }}
            </el-menu-item>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="route.jump" :key="route.jump">
            <i :class="getIcon(route.icon)"></i>
            <span slot="title">{{ route.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<style lang="less" scoped>
@menuBackgroundColor: #373a3d;
@textColor: rgb(191, 203, 217);
@activeTextColor: rgb(64, 158, 255);

.title {
  line-height: 70px;
  height: 50px;
  color: #fff;
  text-align: center;
  cursor: pointer;

}
.el-submenu .el-menu-item {
  background-color: #282929 !important;

  &:hover {
    background-color: #000000 !important;
  }
}
::v-deep .is-active > .el-submenu__title {
  color: #fff !important;
}
.el-submenu__title {
  font-size: 18px !important;

  .el-menu {
    background-color: #2783d8 !important;
  }
}
</style>
  
<script>
  import { getRuleInfo } from "@/api/accountManagement";

export default {
  props: ["fold"],
  data() {
    return {
      myPath: this.$route.path,
      constantRoutes: [],
      cindex: null,
    };
  },
  computed: {},
  watch: {
    $route: function (to, from) {
      this.menuPath();
      // sessionStorage.setItem("previousPath", from.path);
    },
  },
  async created() {
    // const loading = this.$loading({
    //   lock: true,
    //   text: "Loading",
    //   spinner: "el-icon-loading",
    //   background: "rgba(0,0,0,0.7)",
    // });
    try {
      // await this.$nextTick();
      await this.getmenu();
      this.menuPath();
      // loading.close();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    menuPath() {
      this.myPath = this.$route.path;
      const numberOfSlashes = this.$route?.path.split("/").length - 1;
      let routerOne = this.$route.path.substring(
        0,
        this.$route.path.lastIndexOf("/")
      );
      if (numberOfSlashes === 2) {
        const isJump = this.constantRoutes.filter((e) => e.jump === routerOne);
        if (Array.isArray(isJump[0].childMenus)) {
          this.myPath = this.$route.path;
        } else {
          this.myPath = routerOne;
        }
      } else if (numberOfSlashes === 3) {
        this.myPath = this.$route.path.substring(
          0,
          this.$route.path.lastIndexOf("/")
        );
      }
    },

    async getmenu() {
      try {
      //  localStorage.clear("routeList");

        const { data, status } = await getRuleInfo();
        if (status) {
          this.constantRoutes = data;
          localStorage.setItem("routeList", JSON.stringify(data));
        } else {
          console.error("Failed to fetch menu data:", data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getIcon(icon) { 
      return icon ? icon : "el-icon-minus";
    },
  },
};
</script>