import Layout from '@/layout'
const productManagementRouter = {
  component: Layout,
  path: '/productManagement',
  name: '产品管理',
  redirect: '/productManagement/messageList',
  meta: {
    icon: '',
    keepAlive: true, // 需要被缓存
    title: "产品管理"
  },
  children: [
    {
      path: "/productManagement/messageList",
      name: "messageList",
      meta: { title: "产品订单信息" },
      component: () => import('../views/productManagement/message/list.vue')
    },
    {
      path: "/productManagement/ordermanagement",
      name: "ordermanagement",
      meta: { title: "订单管理" },
      component: () => import('../views/productManagement/management/list.vue')
    },
    {
      path: "/productManagement/messageList/addMessage",
      name: "addMessage",
      meta: { title: "新增产品" },
      component: () => import('../views/productManagement/message/addMessage.vue')
    },
    {
      path: "/productManagement/ordermanagement/detailMessage",
      name: "detailMessage",
      meta: { title: "订单详情" },
      component: () => import('../views/productManagement/management/detail.vue')
    },
  ]
}
export default productManagementRouter