import Layout from '@/layout'
const accountManagementRouter = {
    component: Layout,
    redirect: '/accountManagement/admin',
    path: '/accountManagement',
    name: '账号管理',
    meta: {
        title: '账号管理',
        icon: ''
    },
    children: [
        {
            path: '/accountManagement/admin',
            name: 'admin',
            meta: { title: "管理台账号列表" },
            component: () => import('../views/accountManagement/admin/list.vue')
        },
        {
            path: "/accountManagement/informationEmployees",
            name: "informationEmployees",
            meta: { title: "员工信息列表" },
            component: () => import('../views/accountManagement/informationEmployees/list.vue')
        },
        {
            path: "/accountManagement/staffCards",
            name: "staffCards",
            meta: { title: "员工身份审核" },
            component: () => import('../views/accountManagement/staffCards/list.vue')
        },
        {
            path: "/accountManagement/routeManagement",
            name: "routeManagement",
            meta: { title: "路由管理" },
            component: () => import('../views/accountManagement/routeManagement/list.vue')
        },
        {
            path: "/accountManagement/role",
            name: "role",
            meta: { title: "身份管理" },
            component: () => import('../views/accountManagement/role.vue')
        },
    ]

}
export default accountManagementRouter
