import Layout from '@/layout'
const financialManagementgRouter = {
    component: Layout,
    redirect: '/financialManagementg',
    path: '/financialManagementg',
    meta: {
        icon: '',
        // title: "财务管理"
    },
    children: [
        {
            path: "/financialManagementg",
            name: "financialManagementg",
            meta: { title: "财务管理", keepAlive: true, },// 需要被缓存},
            component: () => import('../views/financialManagementg/list.vue')
        },
        {
            path: "/financialManagementg/amend",
            name: "amend",
            meta: { title: "提现账号信息", tabBar: true, auth: false },

            component: () => import('../views/financialManagementg/amend.vue')
        },
    ]
}
export default financialManagementgRouter
