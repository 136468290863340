<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="page">
    <el-container>
      <el-aside class="el-aside" :style="{ width: fold ? '60px' : '220px' }">
        <navMenu :fold="fold"></navMenu>
      </el-aside>
      <el-container>
        <el-header>
          <headers @handleFold="handleFold" :fold="fold"></headers>
        </el-header>
        <tags-view v-if="needTagsView" />
        <el-main style="overflow: auto; background: rgb(240, 242, 245)">
          <router-view>
            <section>
              <router-view />
            </section>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Headers from "@/components/header";
import navMenu from "@/components/navMenu";
import TagsView from "@/components/TagsView/index.vue";
// import { mapState } from "vuex";

export default {
  // name: 'index',
  components: {
    Headers,
    navMenu,
    TagsView,
  },
  computed: {
    needTagsView() {
      return true;
    },
  },
  data() {
    return {
      fold: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    /**
     * 折叠
     */
    handleFold() {
      this.fold = !this.fold;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-aside::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.el-header {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 60px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.el-aside {
  background-color: #373a3d;
  color: #333;
  text-align: center;
  line-height: 200px;
  // height: 98vh;
  text-align: left;
  transition: width 0.2s;
}

.el-main {
  background-color: #fff;
  color: #333;
  height: calc(100vh - 100px);
}
</style>
