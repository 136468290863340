import Layout from '@/layout'
const operationLogRouter = {
    component: Layout,
    path: '/operationLog',
    name: '操作日志',
    redirect: '/operationLog',

    meta: {
        // title: '操作日志',
        icon: ''
    },
    children: [
        {
            path: '/operationLog',
            name: 'operationLog',
            meta: { title: "操作日志" },
            component: () => import('../views/operationLog/list.vue')
        }
    ]

}
export default operationLogRouter
