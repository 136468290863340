import Layout from '@/layout'
const localityServeRouter = {
    component: Layout,
    path: '/archiveManagement',
    name: '档案管理',
    redirect: '/archiveManagement/profileList',

    meta: {
        title: '档案管理',
        icon: ''
    },
    children: [
        {
            path: '/archiveManagement/profileList',
            name: 'profileList',
            meta: { title: "档案资料管理" },
            component: () => import('@/views/archiveManagement/profile/list.vue')
        },
        {
            path: "/archiveManagement/profileList/addProfile",
            name: "addProfile",
            meta: { title: "录入信息" },
            component: () => import('@/views/archiveManagement/profile/addProfile.vue')
        },
        {
            path: "/archiveManagement/profileList/detailProfile",
            name: "detailProfile",
            meta: { title: "详情" },
            component: () => import('@/views/archiveManagement/profile/detailProfile.vue')
        },
        {
            path: "/archiveManagement/profileList/editProfile",
            name: "editProfile",
            meta: { title: "编辑信息" },
            component: () => import('@/views/archiveManagement/profile/editProfile.vue')
        },
        {
            path: "/archiveManagement/dataType",
            name: "dataType",
            meta: { title: "资料类型管理" },

            component: () => import('@/views/archiveManagement/dataType/list.vue')
        },
        {
            path: "/archiveManagement/dataType/detilType",
            name: "detilType",
            meta: { title: "查看资料类型" },
            component: () => import('@/views/archiveManagement/dataType/detilType.vue')
        },
        {
            path: "/archiveManagement/dataType/editType",
            name: "editType",
            meta: { title: "编辑资料类型" },
            component: () => import('@/views/archiveManagement/dataType/editType.vue')
        },
        {
            path: "/archiveManagement/profileList/detailData",
            name: "detailData",
            meta: { title: "查看详细资料" },
            component: () => import('@/views/archiveManagement/profile/detailData.vue')
        },


    ]

}
export default localityServeRouter
