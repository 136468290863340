import Layout from '@/layout'
const volunteerInformationRouter = {
    component: Layout,
    path: '/volunteerInformation',
    name: '志愿者信息',
    redirect: '/volunteerInformation/volunteerList',

    meta: {
        title: '志愿者信息',
        icon: '',
        keepAlive: true, // 需要被缓存
    },
    children: [
        {
            path: '/volunteerInformation/volunteerList',
            name: 'volunteerList',
            meta: { title: "志愿者列表" },
            component: () => import('../views/volunteerInformation/volunteerList/list.vue')
        },
        {
            path: '/volunteerInformation/enterList',
            name: 'enterList',
            meta: { title: "志愿者入驻审核" },
            component: () => import('../views/volunteerInformation/enter/list.vue')
        },
        {
            path: '/volunteerInformation/activityList',
            name: 'activityList',
            meta: { title: "志愿者活动列表" },
            component: () => import('../views/volunteerInformation/activity/list.vue')
        },

    ]

}
export default volunteerInformationRouter
