import request from '../request/http';



// 菜单栏

export const getgetVillageNews = (params) => {
    return request.get('/service-care-center/centerMenu/getCenterMenuList', { params })
}
export const menuBar = (params) => {
    return request.get("/service-care-center/centerMenu/getCenterMenuList", { params });
}
//获取当前用户权限信息
export const getRuleInfo = (params) => {
    return request.get("/service-care-center/centerAdminLogin/getRuleInfo", { params });
}
// 新增修改菜单栏
export const routeList = (data) => {
    return request.post("/service-care-center/centerMenu/addOrUpdCenterMenu", data);
}
// 删除菜单栏
export const routeDl = (params) => {
    return request.delete("/service-care-center/centerMenu/delCenterMenu", { params });
}
// 更改菜单状态
export const routeDel = (params) => {
    return request.post("/service-care-center/centerMenu/updCenterMenuStatus", null, { params });
}

//获取后台账号列表信息

export const getCenterAdminPage = (params) => {
    return request.get("/service-care-center/centerAdmin/getCenterAdminPage", { params });
}

//获取角色列表信息

export const getCenterRoleList = (params) => {
    return request.get("/service-care-center/centerRole/getCenterRoleList", { params });
}
//新增、修改账号

export const saveOrUpdateCenterAdmin = (params) => {
    return request.post("/service-care-center/centerAdmin/saveOrUpdateCenterAdmin", params);
}
//获取账号基本信息

export const getInfo = (params) => {
    return request.get("/service-care-center/centerAdmin/getInfo", { params });
}
//修改密码

export const updPassword = (params) => {
    return request.post("/service-care-center/centerAdmin/updPassword", null, { params });
}//删除账号

export const delCenterAdminInfo = (params) => {
    return request.delete("/service-care-center/centerAdmin/delCenterAdminInfo", { params });
}
//获取员工信息列表

export const getCenterStaffPage = (params) => {
    return request.get("/service-care-center/centerStaff/getCenterStaffPage", { params });
}
//新建、修改员工

export const saveOrUpdCenterStaff = (params) => {
    return request.post("/service-care-center/centerStaff/saveOrUpdCenterStaff", params);
}
//删除员工、审核拒绝

export const delCenterStaff = (params) => {
    return request.post("/service-care-center/centerStaff/delCenterStaff", null, { params });
}

//停用、启用员工|审核通过

export const updStatus = (params) => {
    return request.post("/service-care-center/centerStaff/updStatus", null, { params });
}
//新增或修改角色

export const saveOrUpdateCenterRole = (params) => {
    return request.post("/service-care-center/centerRole/saveOrUpdateCenterRole", params);
}
//获取角色详情

export const getCenterRoleInfo = (params) => {
    return request.get("/service-care-center/centerRole/getCenterRoleInfo", { params });
}
//删除角色
export const delCenterRole = (params) => {
    return request.delete("/service-care-center/centerRole/delCenterRole", { params });
}
//找回密码
export const findPassword = (params) => {
    return request.post("/service-care-center/centerAdminLogin/findPassword", params);
}