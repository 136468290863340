<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="page">
    <div class="page-left">
      <div @click="handleFold">
        <div class="el-icon-s-fold" v-show="!fold"></div>
        <div class="el-icon-s-unfold" v-show="fold"></div>
      </div>
      <!-- <div class="el-icon-refresh-left" @click="handleRefresh"></div> -->
      <Breadc></Breadc>
      <!-- <Breadcrumb></Breadcrumb> -->
    </div>
    <div style="display: flex">
      <!-- <div class="date">
        今天是你在..的第<span class="tips">{{ time }}</span
        >天
      </div> -->
      <div class="head">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            欢迎您! {{ "\u00A0" }}
            <span class="nickname">{{ form.nickname }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="a">个人中心</el-dropdown-item>
            <el-dropdown-item command="b">修改密码</el-dropdown-item> -->
            <el-dropdown-item command="c">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
// import {formatDate} from '@/utils/formatDate'
// import Breadcrumb from "@/components/Breadcrumb";
import Breadc from "@/components/breadC";
import { getAdminInfo } from "@/api/component";
export default {
  props: ["fold"],
  components: {
    // NavLeft,
    Breadc,
    // Breadcrumb
  },
  data() {
    return {
      name: "",
      // 在线日期
      time: "",
      // 当前日期
      nowDate: "",
      form: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDate();
    this.getInfo();
    this.getHeight();
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      if (command == "c") {
        this.$router.push("/Login");
        localStorage.clear();
      }
    },
    /**
     * 刷新页面
     */
    handleRefresh() {
      // this.$router.replace({
      //   path: "/blank",
      //   query: {
      //     t: Date.now(),
      //   },
      // });
      // location.reload();
      // this.$router.go(0)
      // eslint-disable-next-line no-self-assign
      // window.location.href = window.location.href;
    },
    /**
     * 折叠
     */
    handleFold() {
      this.$emit("handleFold");
    },
    /**
     * 获取角色信息
     */
    async getInfo() {
      try {
        const { status, data } = await getAdminInfo();
        if (status) {
          // console.log(data,'data');
          this.form = data;
        }
      } catch (error) {
        console.log(error);
      }
      // this.$api.Login.homeUserInfo().then((res) => {
      //   console.log(res.data.data, "roieid");
      //   let data = res.data.data;
      //   this.name = data.userName;
      //   this.$store.commit("saveRouteID", data);
      //   localStorage.setItem("rouId", data.roleId);
      //   localStorage.setItem("routeInfo", JSON.stringify(data));
      //   this.getDifferDate(this.formatTime(data.createdAt), this.nowDate, 1);
      // });
    },
    /**
     * 获取当前日期
     */
    getDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate();
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      this.nowDate = year + "-" + month + "-" + day + " " + h + m + s;
    },
    /**
     * 获取与毫秒数的转化比例
     */
    getDifferScale(value) {
      var format;
      // 获取转化比（天数跟毫秒数的比例）
      if (value === 1) {
        format = parseFloat(24 * 60 * 60 * 1000);
      } else if (value === 2) {
        // 获取转化比（小时数跟毫秒数的比例）
        format = parseFloat(60 * 60 * 1000);
      } else if (value === 3) {
        // 获取转化比（分钟数跟毫秒数的比例）
        format = parseFloat(60 * 1000);
      } else if (value === 4) {
        // 获取转化比（秒数跟毫秒数的比例）
        format = parseFloat(1000);
      }
      return format;
    },
    /**
     * 日期格式化
     * @param {传入的时间戳} val
     */
    // formatTime(val){
    //   // let data = new Date(val)
    //   // return  formatDate(data,'yyyy-MM-dd hh:mm');
    // },
    /**
     * 计算天数
     * 相差天数：1，相差小时数：2，相差分钟数：3，相差秒数：4
     */
    getDifferDate(firstDate, secondDate) {
      // 1)将两个日期字符串转化为日期对象
      let startDate = new Date(firstDate);
      let endDate = new Date(secondDate);
      // 2)计算两个日期相差的毫秒数
      let msecNum = endDate.getTime() - startDate.getTime();
      // 3)计算两个日期相差的天数
      this.time = Math.floor(msecNum / this.getDifferScale(1));
    },
    /**
     * 获取自身元素高度
     */
    getHeight() {
      this.$nextTick(() => {
        // let height = document.getElementsByClassName('page')[1].clientHeight
        // this.$store.commit('saveHeaderHeight', height)
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: space-between;
}
.nickname {
  font-size: 15px;
  color: #4c75d6;
}
.page-left {
  display: flex;
  align-items: center;

  div {
    font-size: 25px;
    cursor: pointer;
    margin-right: 10px;
  }

  /* } */
}

.el-icon-refresh-left {
  font-size: 25px;
  cursor: pointer;
}

.date {
  margin-right: 50px;
  line-height: 54px;
}

.tips {
  color: #40a9ff;
  font-size: 24px;
}
</style>