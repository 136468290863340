// import request from '../utils/requset';
import request from '../request/http';

//获得短信验证码
export const getSMSCode = (params) => {
    return request.get('/service-care-center/centerAdminLogin/getSMSCode', { params })
}
//获取当前用户基本信息
export const getAdminInfo = (params) => {
    return request.get('/service-care-center/centerAdminLogin/getAdminInfo', { params })
}
