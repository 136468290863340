import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
// import route from '../store/index'
// import api from '../api/index'

// 档案管理
import archiveManagement from "./archiveManagement.js";

// // 仪表盘
import instrumentsPanel from './instrumentsPanel.js';
// // 资讯管理
import informationManagementRouter from './informationManagement.js';
// // // 产品管理 
import productManagementRouter from './productManagement.js';
// // 活动中心
import activityCenterRouter from './activityCenter.js';
// // 志愿者信息
import volunteerInformationRouter from "./volunteerInformation.js";
// // 康养中心信息设置
import messageSettingsRouter from "./messageSettings.js";
// // 账号管理
import accountManagementRouter from './accountManagement.js';
// // 操作日志
import operationLogRouter from './operationLog.js';
// // 财务管理
import financialManagementgRouter from './financialManagement.js';


Vue.use(VueRouter)
export const routes = [
  {
    path: '/redirect',
    // path: '/Layout',
    component: Layout,
    hidden: true,
  },

  {
    path: '/Login',
    name: 'Login',
    hidden: true
    ,
    component: () => import('../login.vue')
  },
  {
    path: '/findPassword',
    name: 'findPassword',
    hidden: true
    ,
    component: () => import('../findPassword.vue')
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    name: 'Page401', hidden: true
    ,
    meta: { title: '401', noCache: true }
  },
  {
    path: '/404', hidden: true
    ,
    component: () => import('@/views/error-page/404'),
    name: 'Page404',
    meta: { title: '404', noCache: true }
  },
  { path: '*', redirect: '/404', hidden: true },
  instrumentsPanel,
  archiveManagement,
  informationManagementRouter,
  productManagementRouter,
  accountManagementRouter,
  operationLogRouter,
  activityCenterRouter,
  volunteerInformationRouter,
  messageSettingsRouter,
  financialManagementgRouter,

]
const router = new VueRouter({
  routes
})
router.beforeEach((to, form, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  } else {
    document.title = '康养中心'
  }
  next()

  //接下来写正常的路由守卫就可以了
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
// 路由拦截
router.beforeEach((to, from, next) => {
  let arr = JSON.parse(localStorage.getItem('routeList'))
  // console.log(to, arr, 'arrr');
  let page = to.path.split('/')
  //   let toPage = page[2] == 'productList' ? '/production/productList' : to.path
  //   let a = []

  // let arra = [
  //   {
  //     childMenus: null,
  //     id: 2,
  //     jump: "/",
  //     title: "仪表盘"
  //   }
  //   , {
  //     id: 2,
  //     jump: "/archiveManagement/profileList",
  //     title: "档案管理",
  //     childMenus: [{
  //       childMenus: null,
  //       jump: "/archiveManagement/profileList",

  //       title: "档案资料管理"
  //     }],

  //   }
  //   ,]
  // let to = {
  //   path: "/"
  // }
  next();
  // let found = false; // 标志位，用于记录是否找到匹配项

  // if (arr) {
  //   arr.forEach(i => {
  //     if (i.childMenus) {
  //       i.childMenus.forEach((e) => { // 使用 forEach 替换 map
  //         if (e.jump === to.path) {
  //           found = true;
  //           next();
  //         }
  //       });
  //     } else {
  //       if (i.jump === to.path) {
  //         found = true;
  //         next();
  //       }
  //     }
  //   });
  
  //   if (!found) { // 如果未找到匹配项
  //     if (to.path === '/Login') { // 如果要去登录页面
  //       next(); // 直接导航到登录页面
  //     } else {
  //       // 寻找第一个子菜单的路径
  //       const firstChildMenu = arr.find(item => item.childMenus)?.childMenus[0];
  //       if (firstChildMenu) {
  //         next(firstChildMenu.jump); // 默认导航到第一个子菜单的路径
  //       } else {
  //         next('404'); // 如果没有子菜单，则导航到 '404' 页面
  //       }
  //     }
  //   }
  // } else {
  //   router.push('/Login'); // 如果 arr 为空，导航到 '404' 页面
  // }
  

})
export default router

